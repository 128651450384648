// 
// Avatar component
// 

.avatar {
  display: inline-block;
  width: 2.85rem;
  height: 2.85rem;
  font-size: 1rem;

  + .avatar {
    margin-left: .2rem;
  }
}

// 
// Grouped avatars
// 

.avatar-group {
  display: inline-flex;

  .avatar + .avatar {
    margin-left: -1.05rem;

    .avatar-image,
    .avatar-letters {
      border: 2px solid $white;
    }
  }

  .avatar-xl + .avatar-xl {
    margin-left: -1.45rem;
  }

  .avatar-lg + .avatar-lg {
    margin-left: -1.2rem;
  }

  .avatar-sm + .avatar-sm {
   margin-left: -.7rem;
  }

  .avatar-xs + .avatar-xs {
    margin-left: -.6rem;
  }
}

// Avatar pic

.avatar-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

// Initials

.avatar-letters {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

// Sizes

.avatar-xl {
  width: 5.5rem;
  height: 5.5rem;
  font-size: 1.85rem;
}

.avatar-lg {
  width: 4.15rem;
  height: 4.15rem;
  font-size: 1.35rem;
}

.avatar-sm {
  width: 1.8rem;
  height: 1.8rem;
  font-size: .85rem;
}

.avatar-xs {
  width: 1.2rem;
  height: 1.2rem;
  font-size: .6rem;
}
