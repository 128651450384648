.navbar {
  z-index: $zindex-navbar;
  @include transition($bg-transition);

  // hover effect
  .dropdown-menu {
    @include media-breakpoint-up(lg) {
      display: block;
      visibility: hidden;
      opacity: 0;
      z-index: 0;
      margin-top: 0;
      box-shadow: $navbar-dropdown-box-shadow-lg;
      transform: translateY(10px);
      @include transition(all .25s);

      &.show {
        opacity: 1;
        visibility: visible;
        transform: none !important;
        z-index: $zindex-navbar;
      }
    }
  }
}

// Navbar for the ecommerce pages

.navbar-ecommerce  {
  position: relative;
  z-index: $zindex-navbar-ecommerce;
}

// Add a bg color to navbars that have .bg-transparent for mobile devices

.navbar-light {
  &.bg-transparent.navbar-toggled {
    background-color: $white !important;
  }
}

.navbar-dark {
  &.bg-transparent.navbar-toggled {
    background-color: $gray-900 !important;
  }
}
