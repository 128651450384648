// Enable background cover 

.bg-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

// Pastel theme colors

@each $color, $value in $theme-colors {
  .bg-pastel-#{$color} {
    background-color: color-level($value, -11) !important;
  }
}

// Gradients

.bg-gradient-light {
  background: linear-gradient(to top, $light 0, #fff 100%);
}

.bg-gradient-light-top {
  background: linear-gradient(to bottom, $light 0, #fff 100%);
}

.bg-gradient-sky {
  background: linear-gradient(to top, $sky 0, #fff 100%);
}

.bg-gradient-sky-top {
  background: linear-gradient(to bottom, $sky 0, #fff 100%);
}

// Grays

@each $key, $value in $grays {
  .bg-gray-#{$key} {
    background: $value !important;
  }
}

// Topography patterns

.bg-topography-light {
  background-image: url("../img/svg/topography-light.svg");
}

.bg-topography-dark {
  background-image: url("../img/svg/topography-dark.svg");
}

// Darken backgrounds on hover
// for normal bgs and pastel too
// see index-portfolio.html for an example

@each $color, $value in $theme-colors {
  .bg-#{$color}-hover {
    transition: background-color .4s linear;

    &:hover {
      background-color: darken($value, 7%) !important;
    }
  }

  .bg-pastel-#{$color}-hover {
    transition: background-color .4s linear;

    &:hover {
      $pastel: color-level($value, -11);
      background-color: darken($pastel, 7%) !important;
    }
  }
}
