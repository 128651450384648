// 
// Pastel buttons
//

@each $color, $value in $theme-colors {
  $background: color-level($value, -11);
  $hover-background: color-level($value, -9);
  $active-background: color-level($value, -7);

  .btn-pastel-#{$color} {
    @include button-variant(
      $background,
      $background,
      $value,
      $hover-background,
      $hover-background,
      $value,
      $active-background,
      $active-background,
      $value
    );
  }
}


//
// White Button
// 

.btn-white {
  @include button-variant(
    $white,
    $gray-200,
    $darkblue,
    $light,
    $gray-200,
    $darkblue,
    $gray-200,
    $gray-200,
    $darkblue
  );
}
