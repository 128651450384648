// 
// Dropdown submenus
//

.dropdown-submenu {
  .dropdown-toggle {
    &:before {
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      content: "\f054";
      display: inline-block;
      font-size: 10px;
      position: absolute;
      right: 14px;
      top: 8px;
      color: #757886;
    }
  }

  @include media-breakpoint-down(lg) {
    .dropdown-menu {
      border-color: $dropdown-border-color;

      .dropdown-item {
        padding-left: 2rem;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .dropdown-menu {
      left: 98%;
      top: -2px;
    }
  }
}

// 
// Dropdown submenus for RTL
// 

body.rtl {
  .dropdown-submenu {
    .dropdown-toggle:before {
      right: initial;
      left: 13px;
      transform: rotateY(180deg);
    }

    @include media-breakpoint-up(lg) {
      .dropdown-menu {
        left: initial;
        right: 98%;
      }
    }
  }
}


// 
// Change original carets
//

.dropdown-toggle::after {
  display: inline-block;
  margin-left: .3rem;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: "\f078";
  font-size: 10px;
  border: none;
  position: relative;
  top: 2px;
}

// Hide carets for navbars and submenus

.navbar,
.dropdown-submenu {
  .dropdown-toggle::after {
    display: none;
  }
}
