.card-columns {
  .card {
    margin-bottom: .75rem;
  }

  @include media-breakpoint-up(md) {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block;
      width: 100%;
    }
  }
}

.card-header {
  border-bottom: 1px solid $card-border-color;
}

.card-footer-btn {
  display: flex;
  align-items: center;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.card-bottom-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 $card-spacer-x 1.5rem;
}

.video-card {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: $border-radius;
  box-shadow: $box-shadow-lg;
  display: flex;
  justify-content: center;
  align-items: center;
}
